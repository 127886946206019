/* You can add global styles to this file, and also import other style files */
@import "@ng-select/ng-select/themes/material.theme.css";
@import 'assets/scss/mat-theme';

@import 'assets/scss/base/typography';
@import 'assets/scss/base/common';

@import 'assets/scss/components/form-field';
@import 'assets/scss/components/button';
@import 'assets/scss/components/tabs';
@import 'assets/scss/components/mat-snakbar';



/* Tailwind */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

/* Telegram ThemeParams */
//body {
//  background-color: var(--tg-theme-bg-color);
//  color: var(--tg-theme-text-color);
//}

.mat-mdc-form-field.input-default {
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }

  .mdc-line-ripple {
    display: none;
  }

  .mat-mdc-text-field-wrapper {
    background: #F5F5F5;
    padding: 0 12px;
    border-radius: 4px;

    .mat-mdc-form-field-infix {
      padding: 0;
      display: flex;
      align-items: center;
    }
  }
}

.mat-mdc-form-field-type-mat-select {
  .mat-mdc-text-field-wrapper {
    @apply rounded bg-safe-grey-200 border-none h-[54px] pl-[30px] pr-[10px];
  }

  .mat-mdc-select-min-line {
    @apply text-lg text-safe-green-500 leading-[1];
    &.mat-mdc-select-placeholder {
      @apply opacity-70;
    }
  }

  .mdc-line-ripple {
    @apply hidden;
  }

  .mat-mdc-select-arrow {
    svg {
      @apply hidden;
    }

    @apply relative before:w-[11px] before:h-[6px] before:absolute before:bg-[url(assets/icons/lang-arrow.svg)] before:bg-no-repeat before:bg-contain;
  }

  .mat-pseudo-checkbox {
    @apply hidden;
  }
}

.mat-pseudo-checkbox {
  @apply hidden #{!important};
}

.panel-select-default {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  .mat-mdc-option {
    @apply text-[18px] leading-[1.2];
  }
}

@layer base {
  html,
  body {
    @apply text-safe-green-500 bg-white;
    font-family: 'Inter';
  }
}

html {
  scroll-behavior: smooth;
}

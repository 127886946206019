.mat-mdc-tab-group {
  .mat-mdc-tab-body-content {
    overflow-y: hidden;
  }
  .mat-mdc-tab-label-container {
    .mat-mdc-tab-labels {
      gap: 8px;
    }
    .mdc-tab {
      border-radius: 4px;
      font-size: 10px;
      font-weight: 700;
      text-transform: uppercase;
      color: #022D35;
      padding: 8px 12px;
      min-width: unset;
      height: auto;
      flex-grow: unset !important;
      .mdc-tab__text-label {
        color: #022D35;
        transition-delay: 0ms;
      }
      &.mdc-tab--active {
        background: #022D35;
        color: #FFFFFF;
        .mdc-tab__text-label {
          color: #FFFFFF;
        }
      }
      .mdc-tab__content {
        min-width: unset;
        height: auto;
      }
      .mdc-tab-indicator, .mdc-tab__ripple, .mat-ripple {
        display: none;
      }
    }
  }
}

.mdc-snackbar {
  &.break-custom {
    word-break: break-word;
  }
  word-break: break-all;
  &.mat-mdc-snack-bar-container {
    .mat-mdc-snackbar-surface {
      padding-bottom: 8px;
      @apply bg-[#022D35F5] rounded-[13px] px-2 shadow-none;
    }

    .mat-mdc-simple-snack-bar {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
    .mat-mdc-snack-bar-label {
      @apply text-white;
    }

    .mat-mdc-snack-bar-actions {
      @apply w-full
    }
    .mat-mdc-button.mat-mdc-snack-bar-action {
      @apply text-white bg-[#1F4A52] rounded-[7px] w-full;
      .mdc-button__label {
        @apply text-white;
      }
    }
  }
}
